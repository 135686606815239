<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="Allowance Types"
      tooltip-title="ALLOW_TYPE"
      :display-breadcrumb="true"
    />
    <div v-if="isLoading" class="mt-28">
      <Loader :content="true" />
    </div>
    <div v-else-if="!isLoading && isEmpty(allowanceTypeList)">
      <NoRecordFound />
    </div>
    <div
      v-else
      id="table-container"
      :class="[leftBlurArea, rightBlurArea]"
      class="overflow-x-auto"
      @scroll.passive="scrollDetect"
    >
      <table class="w-full table-auto whitespace-nowrap border-b border-primary-grey mb-0.5">
        <thead class="bg-bright-gray">
          <tr class="text-left">
            <th
              v-for="head in tableHeads"
              :key="head"
              class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 ltr:pl-4 rtl:pl-0 ltr:pr-0"
            >
              <span class="ltr:border-r rtl:border-l border-primary-grey flex items-center gap-1">
                <span v-i18n="salary">{{ head }}</span>
              </span>
            </th>

            <th class="table_action_th">
              <span v-i18n="dashboard">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(type, index) in allowanceTypeList"
            :key="index"
            class="border-t border-primary-grey text-left"
          >
            <td
              class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
            >
              <span class="font-roboto font-normal text-text-color text-sm">
                {{ type.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </span>
            </td>
            <td
              class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right rtl:pr-4 ltr:px-4"
            >
              <span class="font-roboto font-normal text-text-color text-sm">
                {{ type.description }}
              </span>
            </td>
            <td class="border-primary-grey w-10 px-5">
              <TableAction
                :idx="index"
                :current-user="type"
                :action-list="actionList"
                :right="50"
                :rtl-right="-245"
                @action="typeAction"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <SalaryTypes
      v-if="showSalaryType"
      :header="'Allowance'"
      :editable-object="currentTypeActionBased"
      @update="fetchAllowanceTypes"
    ></SalaryTypes>
    <Pagination
      v-if="allowanceTypeCount > 9"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(allowanceTypeCount, filteredRecordLimit)"
      @filterRecord="fetchAllowanceTypes"
    />

    <UiConfirmationComponent
      heading="Allowance Type"
      button-text="Delete"
      :message="`Are you sure you want to delete ${currentSelectedType.title}`"
      :modal="deleteModelShow"
      :is-loading="isModalLoading"
      @confirm="deleteAllowanceTypeById"
      @cancel="toogleDeleteModel"
    />
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import SalaryTypes from '@src/components/salary-manager/AddSalaryTypesModal.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'SalaryAllowanceTypeView',
  components: {
    TitleWrapper,
    TableAction,
    Loader,
    Pagination,
    SalaryTypes,
    NoRecordFound: () => import('@components/BaseComponent/NoRecordFound.vue'),
    UiConfirmationComponent: () => import('@src/components/UiElements/UIConfirmationModal.vue'),
  },
  mixins: [GeneralMixin, scrollMixin],
  data() {
    return {
      salary: 'salary',
      dashboard: 'dashboard',
      deleteModelShow: false,
      allowanceTypeList: [],
      currentSelectedType: {},
      currentTypeActionBased: null,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      allowanceTypeCount: '--',
      filteredRecordLimit: 10,
      GENERAL_CONSTANTS,
      tableHeads: ['Title', 'Description'],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.salary.isLoading,
      isModalLoading: (state) => state.salary.isModalLoading,
      showSalaryType: (state) => state.salary.showModal,
    }),
  },
  watch: {
    showSalaryType: {
      handler(value) {
        if (!value) this.currentTypeActionBased = null
      },
    },
  },
  /**
   * Mounted hook
   * @description Setting static right bar data
   */
  mounted() {
    this.fetchAllowanceTypes()
    this.setRightbarData()
  },
  methods: {
    isEmpty,
    async deleteAllowanceTypeById() {
      await this.deleteAllowanceType(this.currentSelectedType.id)
      this.toogleDeleteModel()
      this.fetchAllowanceTypes()
    },
    async fetchAllowanceTypes(range) {
      const paginationLimit = {
        skip: range?.skip || this.GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || this.GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      const [res, err] = await this.getAllowanceTypes(paginationLimit)
      if (!err) {
        this.allowanceTypeList = res.data.records
        this.allowanceTypeCount = res.data?.meta?.total_records
        this.setRightbarData(this.allowanceTypeCount)
      }
    },

    /**
     * Type Action
     * @param {string} eventName
     * @param {object} currentType
     * @param {number} idx
     * @returns {void}
     * @description Edit or delete current type on the basis of action
     */
    typeAction(action, currentType, idx) {
      switch (action) {
        case 'Edit':
          this.showModal()
          this.currentTypeActionBased = currentType
          break
        case 'Delete':
          this.currentSelectedType = currentType
          this.toogleDeleteModel()
          break
      }
    },

    setRightbarData(count = null) {
      const rightBarContent = {
        header: {
          title: 'Allowance',
          buttons: [
            {
              title: 'Add Allowance Type',
              classes: ['primary-button-right'],
              action: { name: 'salary/showModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_ALLOWANC_TYPE',
                value: count || GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total No of Allowance Type',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
    },

    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('salary', ['getAllowanceTypes', 'showModal', 'deleteAllowanceType']),
  },
}
</script>

<style lang="scss" module>
tr:nth-child(even) {
  background-color: var(--ghost-white);
}

.info {
  top: 35px;
  border: 1px solid var(--text-color-info);
  &::before {
    position: absolute;
    top: -7px;
    right: 30px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--text-color-info);
    border-left: 1px solid var(--text-color-info);
    transform: rotate(45deg);
  }
}
</style>
